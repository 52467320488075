import { saveAs } from 'file-saver';

function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook();

    this.SheetNames = [];

    this.Sheets = {};
}

export default function ExportAll(data, fileTitle) {
    import('xlsx').then((XLSX) => {
        const wb = new Workbook();
        var wscols = [
            { wpx: 180 },
            { wpx: 200 },
            { wpx: 120 },
            { wpx: 100 },
            { wpx: 80 },
            { wpx: 80 },
        ];

        var ws = XLSX.utils.json_to_sheet(data);

        ws['!cols'] = wscols;

        wb.SheetNames.push(fileTitle);
        wb.Sheets[fileTitle] = ws;

        let wbout = XLSX.utils.sheet_to_csv(ws);

        saveAs(new Blob(["\ufeff" + wbout], { type: "application/octet-stream" }), fileTitle + '.csv');

    });
}
