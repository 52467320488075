import { makeStyles } from "@material-ui/core/styles";
import ContentLoader from "react-content-loader";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme) => ({
  imagePlot: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  plot: {
    padding: theme.spacing(1),
    height: 350,
    width: "100%",
  },
}));

export default function PlotLoader(props) {
  const classes = useStyles();

  return (
    <div className={classes.imagePlot}>
      <div id="loader-plot" className={classes.plot}>
        <AutoSizer>
          {({ height, width }) => (
            <ContentLoader height={height} width={width} {...props}>
              <rect
                x="0"
                y={height / (Math.random() * 10 + 1)}
                rx="0"
                ry="0"
                width={width / 10}
                height={height}
              />
              <rect
                x={width / 5}
                y={height / (Math.random() * 10 + 1)}
                rx="0"
                ry="0"
                width={width / 10}
                height={height}
              />
              <rect
                x={(2 * width) / 5}
                y={height / (Math.random() * 10 + 1)}
                rx="0"
                ry="0"
                width={width / 10}
                height={height}
              />
              <rect
                x={(3 * width) / 5}
                y={height / (Math.random() * 10 + 1)}
                rx="0"
                ry="0"
                width={width / 10}
                height={height}
              />
              <rect
                x={(4 * width) / 5}
                y={height / (Math.random() * 10 + 1)}
                rx="0"
                ry="0"
                width={width / 10}
                height={height}
              />
            </ContentLoader>
          )}
        </AutoSizer>
      </div>
    </div>
  );
}
